<template>
  <div class="outBox">
    <div class="content">
      <div class="footer_ul flex">
        <ul>
          <li>产品</li>
          <li>
            <router-link to="/product?id=0"> <span>美颜SDK</span> </router-link>
          </li>
          <li>
            <router-link to="/product/intelligent?id=0">
              <span>智能识别</span>
            </router-link>
          </li>
          <li>
            <router-link to="/product/core?id=0">
              <span>核心技术</span>
            </router-link>
          </li>
        </ul>
        <ul>
          <li>应用场景</li>
          <li>
            <router-link to="/scene?id=0"> <span>直播场景</span> </router-link>
          </li>
          <li>
            <router-link to="/scene?id=1"> <span>视频场景</span> </router-link>
          </li>
          <li>
            <router-link to="/scene?id=2">
              <span>智慧屏场景</span>
            </router-link>
          </li>
        </ul>
        <ul>
          <li>支持与服务</li>
          <li>
            <router-link to="/developer"> <span>开发者中心</span> </router-link>
          </li>
          <li>
            <router-link to="/home/serve">
              <span>服务协议</span>
            </router-link>
          </li>
          <li>
            <router-link to="/home/privacy">
              <span>隐私保护政策</span>
            </router-link>
          </li>
          <li>
            <router-link to="/demo"> <span>Demo下载</span> </router-link>
          </li>
          <li>
            <router-link to="/home/resourcePool">
              <span>拓幻资源库</span>
            </router-link>
          </li>
        </ul>
        <ul>
          <li>关于我们</li>
          <li>
            <router-link to="/home/company">
              <span>企业介绍</span>
            </router-link>
          </li>
          <li>
            <router-link to="/home/join"> <span>加入我们</span> </router-link>
          </li>
          <li>
            <router-link to="/call"> <span>联系我们</span> </router-link>
          </li>
          <li>
            <router-link to="/home/cooperation">
              <span>商务合作</span>
            </router-link>
          </li>
        </ul>
      </div>
      <div class="footer_tel flex">
        <div class="tel_p">
          <img src="./assets/logo_bottom@2x.png" width="142" />
          <p>让幻想不止于此</p>
        </div>

        <div class="tel_ul">
          <ul class="flex">
            <li>商务合作</li>
            <li>
              <img src="./assets/icon_lianxi_tel@2x.png" />
              17136231898(同微信号)
            </li>
            <li><img src="./assets/icon_lianxi_qq@2x.png" /> 3599350770</li>
            <li>
              <img src="./assets/icon_lianxi_mail@2x.png" /> sales@tillusory.cn
            </li>
          </ul>
          <ul class="flex">
            <li>渠道合作</li>
            <li>
              <img src="./assets/icon_lianxi_tel@2x.png" />
              13101897258(同微信号)
            </li>
            <li><img src="./assets/icon_lianxi_qq@2x.png" /> 1838852977</li>
            <li>
              <img src="./assets/icon_lianxi_mail@2x.png" /> market@tillusory.cn
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="out_div">
      Copyright © 2017-2021 Tillusory. All Rights Reserved. <a href="https://beian.miit.gov.cn" target="_blank">苏ICP备2022001391号-1</a>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
$width: 79.1%;
.flex {
  display: flex;
  justify-content: space-between;
}
.outBox {
  width: 100%;
  height: 560px;
  background: #1d1d1d;
  font-family: Source Han Sans CN;
  font-size: 14px;
  .content {
    width: $width;
    margin: auto;
    .footer_ul {
      text-align: left;
      padding: 58px 0;
      border-bottom: 1px solid #999;

      li {
        line-height: 38px;
        span {
          font-weight: 400;
          color: #999999;
          cursor: pointer;
        }
      }
      li:first-child {
        color: #fff;
        font-size: 16px;
        font-weight: bold;
      }
      span:hover {
        color: #4c8cf5;
      }
    }
    .footer_tel {
      height: 197px;
      align-items: center;
      .tel_p {
        text-align: center;
        p {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #999999;
          line-height: 38px;
        }
      }
      .tel_ul {
        width: 69%;
        font-weight: 400;
        color: #999999;
        li {
          width: 163px;
          line-height: 42px;
          vertical-align: middle;
        }
        li:first-child {
          color: #fff;
          font-size: 16px;
          margin-left: 0px;
        }
        li:nth-child(2) {
          width: 197px;
        }
        img {
          width: 14px;
          margin-right: 10px;
        }
      }
    }
  }
  .out_div {
    width: 100%;
    height: 54px;
    margin: auto;
    text-align: center;
    line-height: 54px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
    border-top: 1px solid #999;
  }
}
/* 屏幕小于 */
@media screen and (max-width: 1440px) {
  .outBox {
    width: 1440px;
  }
}
</style>